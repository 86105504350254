import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import countryList from 'react-select-country-list';

import { Checkbox, TextField } from '@cimpress/react-components';

import DottedLine from '../DottedLine';
import { SelectField } from '../types';
import AddressTypeSelector from './AddressTypeSelector';
import CountrySelector from './CountrySelector';
import messages from './messages';
import { AddressType, LocationState, defaultLocationState } from './state';

type LocationOptionIndex = 'poBox';

type LocationInputPanelProps = {
  locationState: LocationState;
  setLocationState: (v: LocationState) => void;
  disabled: boolean;
  isPostalCodeValid: boolean;
};

const LocationInputPanel = ({
  locationState,
  setLocationState,
  disabled,
  isPostalCodeValid,
}: LocationInputPanelProps) => {
  const { formatMessage } = useIntl();

  const pickupPointsDisabled = locationState.deliveryCountry !== defaultLocationState.deliveryCountry;
  const countriesDisabled = locationState.pickupPointUrl !== defaultLocationState.pickupPointUrl;

  const onChangeDeliveryCountry = (selection: SelectField) => {
    if (isNil(selection)) {
      setLocationState({ ...locationState, deliveryCountry: defaultLocationState.deliveryCountry });
    } else {
      setLocationState({
        ...locationState,
        deliveryCountry: selection.value,
        pickupPointUrl: defaultLocationState.pickupPointUrl,
      });
    }
  };

  const onChangePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationState({ ...locationState, postalCode: e.target.value });
  };

  const onChangeLocationOptions = (e: React.ChangeEvent<HTMLInputElement>, payload: LocationOptionIndex) => {
    const newState = { ...locationState, [payload]: !locationState[payload] };
    setLocationState(newState);
  };

  const onChangeAddressType = (selection: SelectField) => {
    const newState = { ...locationState, addressType: selection.value as AddressType };
    setLocationState(newState);
  };

  const onChangePickupPointUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationState({ ...locationState, pickupPointUrl: e.target.value });
  };
  return (
    <div>
      <h3>{formatMessage(messages.deliveryCountry)}</h3>
      <CountrySelector value={locationState.deliveryCountry} onChange={onChangeDeliveryCountry} isDisabled={disabled || countriesDisabled} />
      <h3>{formatMessage(messages.postalCode)}</h3>
      <TextField
        type="text"
        label={formatMessage(messages.postalCode)}
        value={locationState.postalCode}
        onChange={onChangePostalCode}
        disabled={disabled || countriesDisabled}
        helpText={
          !isPostalCodeValid && (
            <span>
              {formatMessage(messages.postalCodeErrorMessage, {
                country: countryList().getLabel(locationState.deliveryCountry),
              })}
            </span>
          )
        }
        status={isPostalCodeValid ? undefined : 'error'}
      />

      <h3>{formatMessage(messages.addressType)}</h3>
      <AddressTypeSelector value={locationState.addressType} onChange={onChangeAddressType} isDisabled={disabled || countriesDisabled} />

      <h3>{formatMessage(messages.locationOptions)}</h3>
      <Checkbox
        label={formatMessage(messages.poBox)}
        payload="poBox"
        checked={locationState.poBox}
        onChange={onChangeLocationOptions}
        disabled={disabled || countriesDisabled}
      />
      <DottedLine />
      <h4 style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}> Or </h4>
      <DottedLine />
      <h3>{formatMessage(messages.pickupPointUrl)}</h3>
      <TextField
        type="text"
        label={formatMessage(messages.pickupPointUrl)}
        value={locationState.pickupPointUrl}
        onChange={onChangePickupPointUrl}
        disabled={disabled || pickupPointsDisabled}
      />
    </div>
  );
};

export default LocationInputPanel;

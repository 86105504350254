import { useQuery } from 'react-query';

import { fetchWithAuth } from '../services/fetchWithAuth';

type Attribute = {
  class: string;
  attributeKey: string;
  attributeValue: string;
};

type ProductConfiguration = {
  mcpSku: string;
  attributes: Attribute[];
};

export const useProductConfiguration = (productConfigurationUrl: string) => {
  return useQuery(
    ['productConfiguration', productConfigurationUrl],
    () => fetchWithAuth<ProductConfiguration>({ endpointUrl: productConfigurationUrl }),
    { enabled: !!productConfigurationUrl },
  );
};

import { CartNavigationTabId, NavigationTabId } from '../components/inputPanels/state';
import {
  ClearDetailsAction,
  CLEAR_DETAILS,
  ProductCardIndexAndDetails,
  SetDetailsAction,
  SET_DETAILS,
  TabWasClickedAction,
  CLICK_TAB,
  CartTabWasClickedAction,
} from './actionTypes';
import { defaultDetails } from './defaults';

export const getPayloadFromTabVisit = (visitedTabId: NavigationTabId): TabWasClickedAction => ({
  type: CLICK_TAB,
  payload: { visitedTabId },
});

export const getPayloadFromCartTabVisit = (visitedTabId: CartNavigationTabId): CartTabWasClickedAction => ({
  type: CLICK_TAB,
  payload: { visitedTabId },
});

export const clearDetails = (): ClearDetailsAction => {
  return {
    type: CLEAR_DETAILS,
    payload: defaultDetails,
  };
};

export const setDetails = (details: ProductCardIndexAndDetails): SetDetailsAction => {
  return {
    type: SET_DETAILS,
    payload: details,
  };
};

import { useMutation, useQuery } from 'react-query';

import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient';

import { AppSettings, GlobalSettings } from '../components/types';
import { APP_SETTINGS_QUERY_KEY, GLOBAL_SETTINGS_QUERY_KEY } from '../constants';
import { getAppSettings, updateAppSettings } from '../services/customizr';

export const useAppSettings = ({ onSuccess }: { onSuccess?: (data: AppSettings) => void }) => {
  return useQuery([APP_SETTINGS_QUERY_KEY], getAppSettings, onSuccess ? { onSuccess } : {});
};

export const useNewAppSettings = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation(updateAppSettings, {
    onSuccess,
  });
};

export const useGlobalSettings = ({ onSuccess }: { onSuccess?: (data: GlobalSettings) => void }) => {
  return useQuery([GLOBAL_SETTINGS_QUERY_KEY], getSettings as () => Promise<GlobalSettings>);
};

import _ from 'lodash';

import { Caveat, DELIVERY_CAVEATS } from '@cimpress-technology/caveats';

import { ConfigurationCreation, VariableAttribute, createConfigurationUrl } from '../services/configuration';
import {
  CartEvaluation,
  CartEvaluationItem,
  DeliveryOption,
  EcommerceDeliveryOption,
  InvalidItem,
  getCartEvaluation,
  getEcommerceDeliveryOption,
} from '../services/edo';
import fetchWithAuth from '../services/fetchWithAuth';
import { getProduct } from '../services/introduction';
import { TracingRecord, getTracingRecordByUrl } from '../services/itemDeliveryPossibilities';

export type HydratedCartEvaluation = CartEvaluation & {
  keyedItems: { [key: string]: CartEvaluationItem };
  keyedEdos: { [key: string]: EcommerceDeliveryOption };
  deliveryGroupId: string;
  _links: {
    self: { href: string };
    ecommerceDeliveryOptionManager: { name: string; href: string };
  };
};

export async function getHydratedCartEvaluation(url: string): Promise<HydratedCartEvaluation> {
  const cartEvaluation = await getCartEvaluation(url);

  const idpTracingRecordUrls = _.uniq(cartEvaluation.idpResponses.map(ir => ir._links.deliveryDateCollection.href));

  const idpTracingRecords = await Promise.all(
    idpTracingRecordUrls.map(async url => {
      return await getTracingRecordByUrl(url);
    }),
  );

  const idpMap = _.keyBy(idpTracingRecords, '_links.self.href');

  const hydratedItems: CartEvaluationItem[] = await Promise.all(
    cartEvaluation.items.map(async i => {
      if (!i.productConfigurationUrl && i.sku) {
        const quantityAttribute: VariableAttribute = {
          attributeKey: 'quantity',
          attributeValue: `${i.deliverableQuantity}`,
        };
        const configurationCreationBody: ConfigurationCreation = {
          mcpSku: i.sku,
          variables: i.attributes ? i.attributes.concat([quantityAttribute]) : [quantityAttribute],
        };
        const productConfigurationUrl = await createConfigurationUrl(configurationCreationBody);
        const productDetails = await getProduct({ mcpSku: i.sku as string });
        return {
          ...i,
          productConfigurationUrl,
          name: productDetails.name,
        };
      } else {
        const productConfig = await fetchWithAuth<{
          mcpSku: string;
          attributes: { attributeKey: string; attributeValue: string }[];
        }>({ endpointUrl: i.productConfigurationUrl as string, method: 'GET' });
        const productDetails = await getProduct({ mcpSku: productConfig.mcpSku });
        return {
          ...i,
          sku: productConfig.mcpSku,
          attributes: productConfig.attributes,
          name: productDetails.name,
        };
      }
    }),
  );

  cartEvaluation.deliveryOptions = cartEvaluation.deliveryOptions.sort((a, b) => {
    if (_.isNil(a.date) && _.isNil(b.date)) {
      return a.name.localeCompare(b.name);
    } else if (_.isNil(a.date)) {
      return 1;
    } else if (_.isNil(b.date)) {
      return -1;
    } else {
      return a.date.localeCompare(b.date);
    }
  });

  cartEvaluation.deliveryOptions = cartEvaluation.deliveryOptions.map(edo => {
    edo.items = edo.items.map(item => {
      const idpUrl = _.find(cartEvaluation.idpResponses, ir => ir.key === item.key && ir.name === edo.name)?._links
        .deliveryDateCollection.href;
      const hydratedItem = { ...item };

      if (idpUrl) {
        hydratedItem.idpTracingRecord = idpMap[idpUrl];
      }
      return hydratedItem;
    });

    return edo;
  });

  const validatedDeliveryOptions = _.uniq(cartEvaluation.deliveryOptions.map(d => d.name));
  const unconsideredIdpResponses = cartEvaluation.idpResponses.filter(r => !validatedDeliveryOptions.includes(r.name));

  const groupedUnconsideredResponses = _.groupBy(unconsideredIdpResponses, r => r.name);

  const unconsideredDeliveryOptions: DeliveryOption[] = Object.values(groupedUnconsideredResponses).map(
    (responses, index) => {
      const deliveryOptionName = responses[0].name;
      const missingItems = cartEvaluation.items.filter(i => !responses.map(r => r.key).includes(i.key));
      const invalidItems: InvalidItem[] = responses
        .map(r => {
          return createInvalidItem(
            r.key,
            DELIVERY_CAVEATS.INFEASIBLE_OPTION,
            idpMap[r._links.deliveryDateCollection.href],
          );
        })
        .concat(missingItems.map(i => createInvalidItem(i.key, DELIVERY_CAVEATS.INAPPLICABLE_OPTION)));
      return {
        id: `${index}`,
        name: deliveryOptionName,
        tags: [],
        items: [],
        _links: { self: { href: responses[0]._links.ecommerceDeliveryOption.href } },
        invalidItems,
      };
    },
  );

  const deliveryGroupId = cartEvaluation._links.self.href.split('/').at(-3) || ''; // Coerce to string for typescript
  const isInt = cartEvaluation._links.self.href.includes('int-');
  const edoManagerUrl = isInt
    ? `https://int-edos.cimpress.io/groups/${deliveryGroupId}`
    : `https://edos.cimpress.io/groups/${deliveryGroupId}`;

  const combinedDeliveryOptions = cartEvaluation.deliveryOptions.concat(unconsideredDeliveryOptions);

  const uniqueEdoLinks = _.uniq(combinedDeliveryOptions.map(o => o._links.self.href));
  const edos = await Promise.all(uniqueEdoLinks.map(async url => await getEcommerceDeliveryOption(url)));
  const edoMap = _.keyBy(edos, '_links.self.href');

  return {
    ...cartEvaluation,
    deliveryOptions: combinedDeliveryOptions,
    items: hydratedItems,
    keyedItems: _.keyBy(hydratedItems, 'key'),
    deliveryGroupId,
    _links: {
      ...cartEvaluation._links,
      ecommerceDeliveryOptionManager: { href: edoManagerUrl, name: deliveryGroupId },
    },
    keyedEdos: edoMap,
  };
}

function createInvalidItem(key: string, caveat: Caveat, idpTracingRecord?: TracingRecord) {
  return {
    key,
    idpTracingRecord,
    _embedded: {
      caveats: { warnings: [caveat] },
      earliestDeliverableDate: 'N/A',
      earliestDeliverableDateEdoId: 'N/A',
    },
  };
}

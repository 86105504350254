import get from 'lodash/get';

import fetchWithAuth from './fetchWithAuth';

type CarrierService = {
  key: string;
  name: string;
};

type CarrierCapability = {
  key: string;
  name: string;
};

type CarrierServiceResponse = {
  carrierServices: CarrierService[];
  _links: {
    next: {
      href: string;
    };
  };
};

type CarrierCapabilityResponse = {
  capabilities: CarrierCapability[];
};

export const getCarrierServices = async (): Promise<CarrierService[]> => {
  try {
    const carrierServicesList = [];
    let url: string | null = `${process.env.REACT_APP_CARRIERS_SERVICE_URL}/api/v1/carrierServices`;

    do {
      // eslint-disable-next-line no-await-in-loop
      const response: CarrierServiceResponse = await fetchWithAuth<CarrierServiceResponse>({ endpointUrl: url });

      const { carrierServices, _links } = response;
      carrierServicesList.push(...carrierServices);
      url = get(_links, 'next.href', null);
    } while (url);

    return carrierServicesList;
  } catch (err) {
    throw new Error(`An error has occurred when retrieving carrier services`);
  }
};

export const getCarrierCapabilities = async (): Promise<CarrierCapability[]> => {
  try {
    const carrierServiceUrl = process.env.REACT_APP_CARRIERS_SERVICE_URL as string;

    const response = await fetchWithAuth<CarrierCapabilityResponse>({
      endpointUrl: carrierServiceUrl,
      route: 'api/v1/capabilities',
    });

    return response.capabilities;
  } catch (err) {
    throw new Error(`An error has occurred when retrieving carrier capabilities`);
  }
};

import moment, { Moment } from 'moment';

import { ItemFulfillmentCapability } from '../components/inputPanels/state';
import { HydratedCartEvaluation, getHydratedCartEvaluation } from '../helpers/edohydration';
import { Link } from '../types';
import fetchWithAuth from './fetchWithAuth';

export type DeliveryConstraints = {
  carrierServiceCapabilities: string[];
  carrierServices: string[];
};

export type DestinationAddress = {
  country: string;
  postalCode: string;
  isPOBox: boolean;
  isResidential: boolean;
};

export type DeliveryConfiguration = {
  quantity: number;
  deliveryRequestLink: Link;
  isSample: boolean;
  destinationAddress: DestinationAddress | null;
};

export type ItemResponse = {
  skuCode: string;
  itemId: string;
  orderId: string;
  merchantId: string;
  destinationAddress?: DestinationAddress;
  deliveryConfigurations: DeliveryConfiguration[];
  deliveryConstraints: DeliveryConstraints;
  fulfillmentCapabilities: ItemFulfillmentCapability[];
  requestDate: Moment;
  createdDate: string;
  _links: {
    productConfiguration: Link;
    buyerAccount: Link;
    self: Link;
    cartEvaluation?: Link;
  };
};

export type Item = {
  itemId: string;
  orderId: string;
  merchantId: string;
  mcpSku: string;
  destinationAddress?: DestinationAddress;
  deliveryConfigurations: DeliveryConfiguration[];
  deliveryConstraints: DeliveryConstraints;
  fulfillmentCapabilities: ItemFulfillmentCapability[];
  buyerAccountUrl: string;
  productConfigurationUrl: string;
  itemUrl: string;
  requestDate: Moment;
  _links: {
    productConfiguration: Link;
    buyerAccount: Link;
    self: Link;
    cartEvaluation?: Link;
  };
};

export type DeliveryRequest = {
  destinationAddress: {
    city: string;
    street1: string;
    country: string;
    postalCode: string;
    isPOBox?: boolean;
    isResidential?: boolean;
  };
  _links?: {
    pickupPoint?: Link;
  };
};

export type ItemWithDeliveryDetails = Item & {
  deliveryRequest: DeliveryRequest;
};

export type ItemWithCartEvaluation = Item & {
  cartEvaluation?: HydratedCartEvaluation;
};

export const getItem = async (itemId: string, timezone: string | undefined): Promise<Item> => {
  const url = process.env.REACT_APP_ITEM_SERVICE_URL as string;
  const response = await fetchWithAuth<ItemResponse>({ endpointUrl: url, route: `/v1/items/${itemId}` });

  return {
    ...response,
    mcpSku: response.skuCode,
    requestDate: timezone ? moment(response.createdDate).tz(timezone) : moment(response.createdDate),
    buyerAccountUrl: response._links.buyerAccount.href,
    productConfigurationUrl: response._links.productConfiguration.href,
    itemUrl: response._links.self.href,
    fulfillmentCapabilities: response.fulfillmentCapabilities,
  };
};

export const getItemWithDeliveryRequest = async (
  itemId: string,
  timezone: string | undefined,
): Promise<ItemWithDeliveryDetails> => {
  const item = await getItem(itemId, timezone);
  if (item) {
    const deliveryRequest = await fetchWithAuth<DeliveryRequest>({
      endpointUrl: item.deliveryConfigurations[0].deliveryRequestLink.href,
    });
    return {
      ...item,
      deliveryRequest,
    };
  }
  return item;
};

export const getItemWithCartEvaluationDetails = async (
  itemId: string,
  timezone: string | undefined,
): Promise<ItemWithCartEvaluation> => {
  const item = await getItem(itemId, timezone);
  if (item) {
    const cartEvaluationUrl = item._links.cartEvaluation?.href;

    if (cartEvaluationUrl) {
      const cartEvaluation = await getHydratedCartEvaluation(cartEvaluationUrl);
      return {
        ...item,
        cartEvaluation,
      };
    } else {
      return { ...item };
    }
  }
  return item;
};

import fetchWithAuth from './fetchWithAuth';
import { ItemResponse } from './items';

export type FulfillmentGroup = {
  items: ItemResponse[];
};

export type OrderResponse = {
  fulfillmentGroups: FulfillmentGroup[];
  merchantId: string;
  orderId: string;
};

const url = process.env.REACT_APP_ORDER_MANAGER_URL as string;

export const getOrder = async (orderId: string): Promise<OrderResponse> => {
  return await fetchWithAuth({
    endpointUrl: url,
    route: `/v1/orders/${orderId}`,
  });
};

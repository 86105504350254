import { css } from '@emotion/css';
import orderBy from 'lodash/orderBy';
import React from 'react';

import { Caveat, CaveatSeverity } from '@cimpress-technology/caveats/dist/types';
import { colors, Label } from '@cimpress/react-components';

const caveatCss = css`
  margin-top: 10px;
  margin-bottom: 10px;
`;
const errorCodeCss = (color: string) => css`
  color: ${color};
  font-weight: bold;
  cursor: pointer;
  :visited {
    color: ${color};
  }
`;

const caveatCodeColors = new Map<CaveatSeverity, string>([
  [CaveatSeverity.Transient, colors.info.base],
  [CaveatSeverity.Warning, colors.warning.base],
  [CaveatSeverity.HardStop, colors.danger.base],
]);

const severityLabel = (severity: CaveatSeverity) => {
  switch (severity) {
    case CaveatSeverity.Transient: {
      return <Label text="Transient" status="info" />;
    }
    case CaveatSeverity.Warning: {
      return <Label text="Warning" status="warning" />;
    }
    case CaveatSeverity.HardStop: {
      return <Label text="HardStop" status="danger" />;
    }
    default: {
      return <Label text="Unknown" style={{ color: colors.silver }} />;
    }
  }
};

const errorCodeText = (caveat: Caveat) => {
  const color = caveatCodeColors.get(caveat.severity) ?? colors.shale;
  return (
    <a
      className={errorCodeCss(color)}
      target="_blank"
      rel="noreferrer"
      href={`https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/442335235/Caveats#${caveat.code}`}
    >
      {caveat.code}
    </a>
  );
};

export const CaveatTable = ({ caveats }: { caveats: Caveat[] }) => {
  const sortedCaveats = orderBy(caveats, 'severity');

  return (
    <div className={caveatCss}>
      {sortedCaveats.map(c => (
        <div>
          {severityLabel(c.severity)} {errorCodeText(c)}
        </div>
      ))}
    </div>
  );
};

import { AppSettings } from '../components/types';
import { fetchWithAuth } from './fetchWithAuth';

const customizrUrl = process.env.REACT_APP_CUSTOMIZR_URL as string;
const customizrId = 'delivery-calculator';

export const getAppSettings = async () => {
  const requestId = encodeURIComponent(customizrId);
  const route = `v1/resources/${requestId}/settings`;
  return fetchWithAuth({ endpointUrl: customizrUrl, route }).catch(() => null);
};

export const updateAppSettings = async (settings: AppSettings) => {
  const requestId = encodeURIComponent(customizrId);
  const route = `v1/resources/${requestId}/settings`;
  return fetchWithAuth({
    endpointUrl: customizrUrl,
    route,
    method: 'PUT',
    body: settings,
  }).catch(() => null);
};

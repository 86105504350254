import { css } from '@emotion/css';
import moment, { Moment } from 'moment-timezone';
import { useIntl } from 'react-intl';

import DatePicker from '@cimpress/react-components/lib/DatePicker';

import { SelectField } from '../types';
import DeliveryGroupSelector from './DeliveryGroupSelector';
import messages from './messages';
import { DeliveryGroupState } from './state';

type DeliveryGroupInputPanelProps = {
  deliveryGroupState: DeliveryGroupState;
  deliveryGroupLink: string;
  setDeliveryGroupState: (v: DeliveryGroupState) => void;
  disabled: boolean;
  timezone: string | undefined;
};

const edgLinkCss = css`
  float: right;
`;

const DeliveryGroupInputPanel = ({
  deliveryGroupState,
  setDeliveryGroupState,
  disabled,
  deliveryGroupLink,
  timezone,
}: DeliveryGroupInputPanelProps) => {
  const { formatMessage } = useIntl();

  const onChangeDeliveryGroup = (selection: SelectField) => {
    setDeliveryGroupState({ ...deliveryGroupState, deliveryGroupId: selection.value });
  };

  const onChangeRequestDate = (date: string | Moment) => {
    const asMoment = typeof date === 'string' ? moment(date) : date;
    setDeliveryGroupState({ ...deliveryGroupState, requestDate: asMoment });
  };

  const edoManagerLink =
    deliveryGroupLink || `${process.env.REACT_APP_EDO_MANAGER_URL}/groups/${deliveryGroupState.deliveryGroupId}`;

  return (
    <div>
      <h3>{formatMessage(messages.selectADeliveryGroup)}</h3>
      <DeliveryGroupSelector
        value={deliveryGroupState.deliveryGroupId}
        onChange={onChangeDeliveryGroup}
        required
        isDisabled={disabled}
      />
      {deliveryGroupState.deliveryGroupId ? (
        <div className={edgLinkCss}>
          <a href={edoManagerLink} target="_blank" rel="noreferrer">
            {'View EDG Configuration '}
          </a>
        </div>
      ) : (
        <></>
      )}
      <h3>{formatMessage(messages.requestDate)}</h3>
      <DatePicker
        label={formatMessage(messages.selectADate)}
        value={timezone ? deliveryGroupState.requestDate?.tz(timezone) : deliveryGroupState.requestDate}
        onChange={onChangeRequestDate}
        isClearable={true}
        disabled={disabled}
      />
    </div>
  );
};

export default DeliveryGroupInputPanel;

import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = '5obeQI8khPoEnzLiGMW9KgmKJ6qC20tl';

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

export default auth;

import { css } from '@emotion/css';

import { Button, TextField } from '@cimpress/react-components';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

type SearchInputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
};

const buttonCss = css`
  height: 48px;
  width: 20%;
`;

const formContainerCss = css`
  display: flex;
`;

const textFieldCss = css`
  width: 80%;
  padding-right: 5px;
`;

const SearchInput = ({ value, onChange, onSubmit, isLoading, ...props }: SearchInputProps) => {
  return (
    <form onSubmit={onSubmit} className={formContainerCss} {...props}>
      <TextField
        type="search"
        autoFocus={true}
        className={textFieldCss}
        placeholder="Order, Item, or Tracing Record identifier"
        value={value}
        onChange={onChange}
        disabled={isLoading}
      />
      <Button variant="default" className={buttonCss} disabled={isLoading}>
        {isLoading ? <Spinner size="small" /> : 'Search'}
      </Button>
    </form>
  );
};

export default SearchInput;

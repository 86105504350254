import fetchWithAuth from './fetchWithAuth';

type Attribute = {
  class: string;
  attributeKey: string;
  attributeValue: string;
};

type ProductConfiguration = {
  mcpSku: string;
  attributes: Attribute[];
};

type IntroductionResponse = {
  referenceId: string;
  name: string;
  description: string;
};

type FamilyResponse = {
  familySummary: {
    scenes: {
      renderedSceneUri: string;
    }[];
  };
};

export type Product = {
  mcpSku: string;
  name: string;
  description: string;
  sceneUrl: string | undefined;
};

export const getProduct = async ({
  productConfigurationUrl,
  mcpSku,
}: {
  productConfigurationUrl?: string;
  mcpSku?: string;
}): Promise<Product> => {
  let productConfiguration;

  if (productConfigurationUrl) {
    productConfiguration = await fetchWithAuth<ProductConfiguration>({
      endpointUrl: productConfigurationUrl,
    });
  }

  const introductionServiceUrl = process.env.REACT_APP_INTRODUCTION_SERVICE_URL as string;
  const introductionResponse = await fetchWithAuth<IntroductionResponse>({
    endpointUrl: introductionServiceUrl,
    route: `/v1/products/${mcpSku || productConfiguration?.mcpSku}`,
  });

  const familyServiceUrl = process.env.REACT_APP_FAMILY_SERVICE_URL as string;
  const familyResponse = await fetchWithAuth<FamilyResponse>({
    endpointUrl: familyServiceUrl,
    route: '/v2/product-families/mcpsku',
    params: { mcpSku: mcpSku || productConfiguration?.mcpSku },
  }).catch(_ => undefined);

  return {
    mcpSku: introductionResponse.referenceId,
    name: introductionResponse.name,
    description: introductionResponse.description,
    sceneUrl: familyResponse?.familySummary?.scenes?.[0]?.renderedSceneUri,
  };
};

import { css } from '@emotion/css';
import { toLower } from 'lodash';
import moment from 'moment';
import { useState } from 'react';

import DeliveryPossibilitiesComponent from '@cimpress-technology/delivery-possibilities-component';
import { DebugBranch } from '@cimpress-technology/delivery-possibilities-component/dist/services/itemDeliveryPossibilities';
import { Card } from '@cimpress/react-components';

import auth from '../auth';
import { isCustomQuotesProduct } from '../helpers/customquotes';
import { getDefaultPostalCodeOrEmpty } from '../helpers/postalcodes';
import { CalculatorState } from '../hooks/useCalculatorState';
import { useProductConfiguration } from '../hooks/useProductConfiguration';
import DeliveryPossibilitiesHeader from './DeliveryPossibilitiesHeader';
import ResultPanelPreSearch from './ResultPanelPreSearch';

export type ResultCardProps = {
  isLoadingSettings: boolean;
  state: CalculatorState;
  timezone: string | undefined;
  currencyCode: string | undefined;
};

const cardWrapperCss = css`
  align-items: 'center';

  .card-block {
    width: 100%;

    .container {
      width: 100%;
    }

    div:has(.crc-card) {
      width: 100%;
    }

    .crc-card {
      margin: 0 auto 3rem;
      width: 100%;
    }

    .crc-card .crc-card {
      margin-bottom: 0;
    }
  }
`;

const resultCardCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const noSupportCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ResultCard = ({ isLoadingSettings, state, timezone, currencyCode }: ResultCardProps) => {
  const [debugBranches, setDebugBranches] = useState<DebugBranch[]>();

  const { data: productConfiguration } = useProductConfiguration(state.productConfigurationUrl);
  const quantityAttribute = productConfiguration?.attributes.find(
    attribute => attribute.class === 'order' && toLower(attribute.attributeKey) === 'quantity',
  );

  if (isCustomQuotesProduct({ sku: state.mcpSku })) {
    return (
      <Card className={cardWrapperCss}>
        <div className={noSupportCss}>
          <p>
            The Delivery Calculator doesn't support the Custom Quotes product,{' '}
            {state.mcpSku || productConfiguration?.mcpSku}.
          </p>
        </div>
      </Card>
    );
  }

  const quantity = Number(quantityAttribute?.attributeValue ?? 0);

  const postalCode = state.postalCode || getDefaultPostalCodeOrEmpty(state.deliveryCountry);

  const isCompleteSelection =
    state.productConfigurationUrl &&
    state.merchantId &&
    ((state.deliveryCountry && postalCode) || state.pickupPointUrl) &&
    quantity &&
    !isLoadingSettings;

  const onSuccess = (data: DebugBranch[]) => {
    setDebugBranches(data);
  };

  if (!isCompleteSelection) {
    return (
      <Card>
        <ResultPanelPreSearch />
      </Card>
    );
  }

  return (
    <Card className={cardWrapperCss}>
      <div className={resultCardCss}>
        {debugBranches ? (
          <DeliveryPossibilitiesHeader
            // typescript complaining about different versions of moment
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            earliestDeliverableDate={debugBranches[0]?.deliverableDates[0]?.date}
            estimateExpirationDate={moment(debugBranches[0]?.deliverableDates[0]?.expiryDate)}
            timezone={timezone}
          />
        ) : (
          <></>
        )}
        <DeliveryPossibilitiesComponent
          accessToken={auth.getAccessToken()}
          productConfigurationUrl={state.productConfigurationUrl}
          merchantId={state.merchantId}
          quantity={quantity}
          country={state.deliveryCountry}
          postalCode={postalCode}
          pickupPointUrl={state.pickupPointUrl}
          isPOBox={state.poBox}
          addressType={state.addressType}
          minutesToOrderSubmittal={state.minutesToOrderSubmittal}
          requestDateTime={state.requestDate?.toISOString()}
          carrierServices={state.carrierServices}
          carrierServiceCapabilities={state.carrierServiceCapabilities}
          currencyCode={currencyCode}
          fulfillmentCapabilities={state.fulfillmentCapabilities}
          timezone={timezone}
          ignoreInTransitInventory={state.ignoreInTransitInventory}
          onSuccess={data => onSuccess(data)}
        />
      </div>
    </Card>
  );
};

export default ResultCard;

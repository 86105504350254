import React, { useState } from 'react';
import { QueryClient, useQueryClient } from 'react-query';

import SettingsModal from '@cimpress-technology/react-platform-settings';

import auth from '../../auth';
import { APP_SETTINGS_QUERY_KEY, GLOBAL_SETTINGS_QUERY_KEY } from '../../constants';
import { useAppSettings, useNewAppSettings } from '../../hooks/useSettings';
import { AppSettings } from '../types';
import AppSettingsConfig from './AppSettingsConfig';

const supportedLanguages = ['en'];

const Settings = () => {
  const queryClient: QueryClient = useQueryClient();
  const [appSettings, setAppSettings] = useState<AppSettings>({});

  const { isFetching } = useAppSettings({
    onSuccess: (settings: AppSettings) => {
      setAppSettings(settings);
    },
  });

  const { mutate: saveSettings } = useNewAppSettings({
    onSuccess: () => queryClient.invalidateQueries(APP_SETTINGS_QUERY_KEY),
  });

  const onChange = (newEditSettings: AppSettings) => {
    setAppSettings({ ...appSettings, ...newEditSettings });
  };

  const onSave = async () => {
    saveSettings(appSettings);
    await queryClient.invalidateQueries(GLOBAL_SETTINGS_QUERY_KEY);
  };

  const appSettingsContent = <AppSettingsConfig appSettings={appSettings} onChange={onChange} />;

  return isFetching ? (
    <></>
  ) : (
    <SettingsModal
      appSettingsContent={appSettingsContent}
      authToken={auth.getAccessToken()}
      lang="en"
      supportedLanguages={supportedLanguages}
      onSave={onSave}
    />
  );
};

export default Settings;

import { css } from '@emotion/css';

import { useProduct } from '../hooks/useProduct';
import { Item } from '../services/items';

const labelCss = css`
  display: flex;
`;

const labelProductCss = css`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-right: 5px;
`;

const ItemSelectorLabel = ({ item }: { item: Item }) => {
  const { data: product, isLoading: isLoadingProduct } = useProduct(item.productConfigurationUrl);

  const productLabel = isLoadingProduct ? (
    <div>loading...</div>
  ) : (
    <div className={labelProductCss}>{product?.name ?? item.mcpSku}</div>
  );

  return (
    <div className={labelCss}>
      {productLabel}
      <div>{'(' + item.itemId + ')'}</div>
    </div>
  );
};

export default ItemSelectorLabel;

import find from 'lodash/find';
import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import useDeliveryGroups from '../../hooks/useDeliveryGroups';
import messages from './messages';

const DeliveryGroupSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const { isLoading, data: deliveryGroups } = useDeliveryGroups({});

  if (isLoading) {
    return <Select label={formatMessage(messages.loading)} isDisabled />;
  }

  const options = deliveryGroups?.map(group => ({ label: group.name, value: group.id })) ?? [];

  return (
    <Select
      {...props}
      label={formatMessage(messages.deliveryGroup)}
      options={options}
      value={find(options, option => option.value === props.value)}
    />
  );
};

export default DeliveryGroupSelector;

import { useIntl } from 'react-intl';

import { SelectField } from '../types';
import CarrierCapabilitiesSelector from './CarrierCapabilitiesSelector';
import CarrierServicesSelector from './CarrierServicesSelector';
import FulfillmentCapabilitiesSelector from './FulfillmentCapabilitiesSelector';
import messages from './messages';
import { DeliveryConstraintsState, IDPFulfillmentCapability } from './state';

type DeliveryConstraintsPanelProps = {
  deliveryConstraintsState: DeliveryConstraintsState;
  setDeliveryConstraintsState: (v: DeliveryConstraintsState) => void;
  disabled: boolean;
};

const DeliveryConstraintsPanel = ({
  deliveryConstraintsState,
  setDeliveryConstraintsState,
  disabled,
}: DeliveryConstraintsPanelProps) => {
  const { formatMessage } = useIntl();

  const onChangeFulfillmentCapabilities = (selections: SelectField[]) => {
    setDeliveryConstraintsState({
      ...deliveryConstraintsState,
      fulfillmentCapabilities: selections.map(selection => selection.value as IDPFulfillmentCapability),
    });
  };

  const onChangeCarrierServices = (selections: SelectField[]) => {
    setDeliveryConstraintsState({
      ...deliveryConstraintsState,
      carrierServices: selections.map(selection => selection.value),
    });
  };

  const onChangeCarrierCapabilities = (selections: SelectField[]) => {
    setDeliveryConstraintsState({
      ...deliveryConstraintsState,
      carrierServiceCapabilities: selections.map(selection => selection.value),
    });
  };

  return (
    <div>
      <h3>{formatMessage(messages.fulfillmentCapabilities)}</h3>
      <FulfillmentCapabilitiesSelector
        value={deliveryConstraintsState.fulfillmentCapabilities}
        onChange={onChangeFulfillmentCapabilities}
      />
      <h3>{formatMessage(messages.carrierServices)}</h3>
      <CarrierServicesSelector
        value={deliveryConstraintsState.carrierServices}
        onChange={onChangeCarrierServices}
        isDisabled={disabled}
      />
      <h3>{formatMessage(messages.carrierCapabilties)}</h3>
      <CarrierCapabilitiesSelector
        value={deliveryConstraintsState.carrierServiceCapabilities}
        onChange={onChangeCarrierCapabilities}
        isDisabled={disabled}
      />
    </div>
  );
};

export default DeliveryConstraintsPanel;

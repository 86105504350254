import find from 'lodash/find';
import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import messages from './messages';

const AddressTypeSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const options = [
    { label: '', value: undefined },
    { label: formatMessage(messages.residential), value: 'residential' },
    { label: formatMessage(messages.commercial), value: 'commercial' },
  ];

  return (
    <Select
      {...props}
      label={formatMessage(messages.addressType)}
      options={options}
      value={find(options, option => option.value === props.value)}
    />
  );
};

export default AddressTypeSelector;

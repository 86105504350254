import bPromise from 'bluebird';

import { getHydratedCartEvaluation, HydratedCartEvaluation } from '../helpers/edohydration';
import { ItemWithCartEvaluation, getItemWithCartEvaluationDetails } from './items';
import { getOrder } from './orderManager';

export type CartSearchResponse = {
  items?: ItemWithCartEvaluation[];
  cartEvaluation?: HydratedCartEvaluation;
};

export const existingCartResourceSearch = async ({
  resourceId,
  timezone,
}: {
  resourceId: string;
  timezone: string | undefined;
}): Promise<CartSearchResponse | undefined> => {
  const orderPromise = getOrder(resourceId).catch(_ => null);
  const itemPromise = getItemWithCartEvaluationDetails(resourceId, timezone).catch(_ => null);
  const cartEvaluationPromise = getHydratedCartEvaluation(resourceId).catch(_ => null);
  const [order, item, cartEvaluation] = await Promise.all([orderPromise, itemPromise, cartEvaluationPromise]);

  if (order) {
    const items = order.fulfillmentGroups
      .flatMap(fulfillmentGroup => fulfillmentGroup.items)
      .map(item => {
        return {
          ...item,
          mcpSku: item.skuCode,
          requestDate: item.requestDate,
          buyerAccountUrl: item._links.buyerAccount.href,
          productConfigurationUrl: item._links.productConfiguration.href,
          itemUrl: item._links.self.href,
        };
      });

    const itemsWithCartEvaluations = await bPromise.map(items, async item => {
      const cartEvaluationUrl = item._links.cartEvaluation?.href;

      if (cartEvaluationUrl) {
        const cartEvaluation = await getHydratedCartEvaluation(cartEvaluationUrl);
        return {
          ...item,
          cartEvaluation,
        };
      } else {
        return { ...item };
      }
    });
    return { items: itemsWithCartEvaluations };
  } else if (item) {
    return { items: [item] };
  } else if (cartEvaluation) {
    return {
      cartEvaluation,
    };
  }
  return;
};

import { uniqueId } from 'lodash';

import { IconBin, IconAddCircle } from '@cimpress-technology/react-streamline-icons';
import { Accordion, Button } from '@cimpress/react-components';

import ProductInputPanel from './ProductInputPanel';
import { ItemState, ItemsState, ProductState, defaultItem } from './state';

type ItemsInputPanelProps = {
  itemsState: ItemsState;
  setItemsState: (v: ItemsState) => void;
  disabled: boolean;
};

const ItemsInputPanel = ({ itemsState, setItemsState, disabled }: ItemsInputPanelProps) => {
  const onAdd = () => {
    const items = [...itemsState.items];
    const newItem = { ...defaultItem, key: uniqueId() };
    items.push(newItem);
    setItemsState({ items });
  };

  const onRemove = (index: number) => {
    const items = [...itemsState.items];
    items.splice(index, 1);
    setItemsState({ items });
  };

  const addButton = <Button variant="link" onClick={() => onAdd()} icon={<IconAddCircle size="lg" />} />;

  const deleteButton = (index: number) => (
    <Button variant="link" onClick={() => onRemove(index)} icon={<IconBin size="lg" />} />
  );

  const renderItem = (index: number, item: ItemState) => {
    const setSkuOrProductConfigurationText = (skuOrProductConfiguration: string) => {
      const updatedItem = { ...item, skuOrProductConfiguration };
      const updatedItems = [...itemsState.items];
      updatedItems[index] = updatedItem;
      setItemsState({ items: updatedItems });
    };
    const setProductState = (item: ProductState) => {
      const updatedItem = { ...itemsState.items[index], ...item };
      const updatedItems = [...itemsState.items];
      updatedItems[index] = updatedItem;
      setItemsState({ items: updatedItems });
    };

    return (
      <div key={`item-${item.key}`}>
        <Accordion
          title={item.key}
          key={item.key}
          variant="ghost"
          actions={<div style={{ display: 'flex', alignItems: 'center' }}>{deleteButton(index)}</div>}
        >
          <ProductInputPanel
            skuOrProductConfigurationText={item.skuOrProductConfiguration}
            setSkuOrProductConfigurationText={setSkuOrProductConfigurationText}
            productState={item}
            setProductState={setProductState}
            disabled={disabled}
          />
        </Accordion>
      </div>
    );
  };

  return (
    <div>
      {itemsState.items.map((item, index) => renderItem(index, item))}
      {addButton}
    </div>
  );
};

export default ItemsInputPanel;

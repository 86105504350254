import find from 'lodash/find';
import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import { Item } from '../services/items';
import ItemSelectorLabel from './ItemSelectorLabel';
import messages from './messages';

const ItemSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const options =
    props.items?.map((item: Item) => {
      const label = <ItemSelectorLabel item={item} />;
      return { label, value: item.itemId };
    }) ?? [];

  return (
    <Select
      {...props}
      label={formatMessage(messages.items)}
      options={options}
      value={find(options, option => option.value === props.value)}
    />
  );
};

export default ItemSelector;

import find from 'lodash/find';
import { useIntl } from 'react-intl';
import countryList from 'react-select-country-list';

import { Select } from '@cimpress/react-components';

import messages from './messages';

const CountrySelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const options = countryList()
    .getLabels()
    .map(countryName => {
      const countryCode = countryList().getValue(countryName);
      return { value: countryCode, label: countryName };
    });

  return (
    <Select
      {...props}
      label={formatMessage(messages.country)}
      options={options}
      isClearable={true}
      value={find(options, option => option.value === props.value)}
    />
  );
};

export default CountrySelector;

import { Caveat } from '@cimpress-technology/caveats';

import { CartNavigationTabId, NavigationTabId } from '../components/inputPanels/state';

export type TabWasClickedAction = TabClickActionTemplate<typeof CLICK_TAB, TabWasClickedPayload>;
export type CartTabWasClickedAction = TabClickActionTemplate<typeof CLICK_TAB, CartTabWasClickedPayload>;

export type DetailsAction = SetDetailsAction | ClearDetailsAction;

export type SetDetailsAction = DetailsActionTemplate<typeof SET_DETAILS, ProductCardIndexAndDetails>;
export type ClearDetailsAction = DetailsActionTemplate<typeof CLEAR_DETAILS, ProductCardIndexAndDetails>;

export type ProductCardIndexAndDetails = {
  caveats: Caveat[];
  index: number | undefined;
};

type TabWasClickedPayload = {
  visitedTabId: NavigationTabId;
};

type CartTabWasClickedPayload = {
  visitedTabId: CartNavigationTabId;
};

type TabClickActionTemplate<T extends TabClickActionType, P> = {
  readonly type: T;
  readonly payload: P;
};

type DetailsActionTemplate<T extends DetailsActionType, P extends ProductCardIndexAndDetails> = {
  readonly type: T;
  readonly payload: P;
};

type TabClickActionType = typeof CLICK_TAB;

type DetailsActionType = typeof SET_DETAILS | typeof CLEAR_DETAILS;

export const CLICK_TAB = 'CLICK_TAB';
export const SET_DETAILS = 'SET_DETAILS';
export const CLEAR_DETAILS = 'CLEAR_DETAILS';

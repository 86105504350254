import { isEmpty } from 'lodash';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { Component, useState } from 'react';
import { useIntl } from 'react-intl';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconBagsCategory from '@cimpress-technology/react-streamline-icons/lib/IconBagsCategory';
import IconPinLocationAlt from '@cimpress-technology/react-streamline-icons/lib/IconPinLocationAlt';
import IconShop from '@cimpress-technology/react-streamline-icons/lib/IconShop';
import { colors, TabMenu } from '@cimpress/react-components';

import { getDefaultPostalCodeOrEmpty } from '../helpers/postalcodes';
import { CalculatorState } from '../hooks/useCalculatorState';
import { CartTabWasClickedAction } from '../reducers/actionTypes';
import { getPayloadFromCartTabVisit } from '../reducers/actions';
import { OptionCalculatorState } from '../reducers/defaults';
import LocationInputPanel from './inputPanels/CartLocationInputPanel';
import DeliveryGroupInputPanel from './inputPanels/DeliveryGroupInputPanel';
import ItemsInputPanel from './inputPanels/ItemsInputPanel';
import {
  LOCATION_TAB_ID,
  DELIVERY_GROUP_TAB_ID,
  ITEMS_TAB_ID,
  CartNavigationTabId,
  DeliveryGroupState,
  LocationState,
  ItemsState,
} from './inputPanels/state';
import messages from './messages';

type InputTabsProps = {
  state: CalculatorState;
  setDeliveryGroupState: (v: DeliveryGroupState) => void;
  setItemsState: (v: ItemsState) => void;
  setLocationState: (v: LocationState) => void;
  isLoadingItem: boolean;
  dispatch: React.Dispatch<CartTabWasClickedAction>;
  optionCalculatorState: OptionCalculatorState;
  timezone: string | undefined;
};

const formatTab = ({
  id,
  title,
  body,
  icon,
  shouldDisplayAlert,
}: {
  id: CartNavigationTabId;
  title: string;
  body: JSX.Element;
  icon: Component;
  shouldDisplayAlert: () => boolean;
}) => {
  const formattedTitle = (
    <div style={{ display: 'grid', gridTemplateColumns: '10fr 1fr' }}>
      <div>{title}</div>
      <div>{shouldDisplayAlert() ? <IconAlertTriangle color={colors.danger.base} /> : null}</div>
    </div>
  );
  return {
    id,
    title: formattedTitle,
    body,
    icon,
  };
};

const InputTabs = ({
  state,
  setDeliveryGroupState,
  setItemsState,
  setLocationState,
  isLoadingItem,
  dispatch,
  optionCalculatorState,
  timezone,
}: InputTabsProps) => {
  const { formatMessage } = useIntl();

  const [activeTabId, setActiveTabId] = useState<CartNavigationTabId>(DELIVERY_GROUP_TAB_ID);

  const { postalCode, deliveryCountry } = state;
  const validatorExistsForCountry = deliveryCountry ? postcodeValidatorExistsForCountry(deliveryCountry) : false;
  const isPostalCodeValid =
    postalCode && validatorExistsForCountry ? postcodeValidator(postalCode, deliveryCountry) : true;

  const onTabClick = (_: React.FormEvent<HTMLFormElement>, tabId: string) => {
    dispatch(getPayloadFromCartTabVisit(activeTabId as CartNavigationTabId));
    setActiveTabId(tabId as CartNavigationTabId);
  };

  const deliveryGroupInputPanel = (
    <DeliveryGroupInputPanel
      deliveryGroupState={state}
      setDeliveryGroupState={setDeliveryGroupState}
      disabled={isLoadingItem}
      timezone={timezone}
      deliveryGroupLink={state.cartEvaluation?._links.ecommerceDeliveryOptionManager.href || ''}
    />
  );
  const deliveryGroupTab = formatTab({
    id: DELIVERY_GROUP_TAB_ID,
    title: formatMessage(messages.deliveryGroup),
    body: deliveryGroupInputPanel,
    icon: IconShop,
    shouldDisplayAlert: () => !state.deliveryGroupId && optionCalculatorState.visitedTabs[DELIVERY_GROUP_TAB_ID],
  });

  const itemsInputPanel = <ItemsInputPanel itemsState={state} setItemsState={setItemsState} disabled={isLoadingItem} />;
  const itemsTab = formatTab({
    id: ITEMS_TAB_ID,
    title: formatMessage(messages.products),
    body: itemsInputPanel,
    icon: IconBagsCategory,
    shouldDisplayAlert: () =>
      (state.items.length === 0 || !state.items.every(i => !isEmpty(i.productConfigurationUrl))) &&
      optionCalculatorState.visitedTabs[ITEMS_TAB_ID],
  });

  const locationInputPanel = (
    <LocationInputPanel
      locationState={state}
      setLocationState={setLocationState}
      disabled={isLoadingItem}
      isPostalCodeValid={isPostalCodeValid}
    />
  );

  const locationTab = formatTab({
    id: LOCATION_TAB_ID,
    title: formatMessage(messages.location),
    body: locationInputPanel,
    icon: IconPinLocationAlt,
    shouldDisplayAlert: () =>
      (!state.deliveryCountry ||
        (!state.postalCode && !getDefaultPostalCodeOrEmpty(state.deliveryCountry)) ||
        !isPostalCodeValid) &&
      !state.pickupPointUrl &&
      optionCalculatorState.visitedTabs[LOCATION_TAB_ID],
  });

  const tabs = [deliveryGroupTab, itemsTab, locationTab];

  return (
    <TabMenu
      activeTabId={activeTabId}
      tabs={tabs}
      onTabClick={onTabClick}
      tabStyle={{ borderTop: `1px solid ${colors.ocean.base}` }}
    />
  );
};

export default InputTabs;

import find from 'lodash/find';
import { useIntl } from 'react-intl';

import { Select } from '@cimpress/react-components';

import { useMerchants } from '../../hooks/useMerchants';
import messages from './messages';

const MerchantSelector = ({ ...props }) => {
  const { formatMessage } = useIntl();

  const { isLoading, data: merchants } = useMerchants();

  if (isLoading) {
    return <Select label={formatMessage(messages.loading)} isDisabled />;
  }

  const options = merchants?.map(merchant => ({ label: merchant.displayName, value: merchant.merchantId })) ?? [];

  return (
    <Select
      {...props}
      label={formatMessage(messages.merchant)}
      options={options}
      value={find(options, option => option.value === props.value)}
    />
  );
};

export default MerchantSelector;

import { useQuery } from 'react-query';

import { DeliveryGroupResponse, getDeliveryGroups } from '../services/edo';

const useDeliveryGroups = ({
  onError,
  onSuccess,
}: {
  onError?: (error: Error) => void;
  onSuccess?: (data: DeliveryGroupResponse[]) => void;
}) => {
  return useQuery({
    queryKey: ['delivery-groups'],
    queryFn: () => getDeliveryGroups(),
    onError,
    onSuccess,
  });
};

export default useDeliveryGroups;

import bPromise from 'bluebird';
import { find } from 'lodash';

import fetchWithAuth from './fetchWithAuth';
import { getTracingRecord, TracingRecord } from './itemDeliveryPossibilities';
import { DeliveryRequest, getItemWithDeliveryRequest, ItemWithDeliveryDetails } from './items';
import { getOrder } from './orderManager';
import { getOrderRequestItems } from './orderRequest';

export type SearchResponse = {
  items?: ItemWithDeliveryDetails[];
  idpTracingRecord?: TracingRecord;
};

export const existingResourceSearch = async ({
  resourceId,
  timezone,
}: {
  resourceId: string;
  timezone: string | undefined;
}): Promise<SearchResponse | undefined> => {
  const orderPromise = getOrder(resourceId).catch(_ => null);
  const itemPromise = getItemWithDeliveryRequest(resourceId, timezone).catch(_ => null);
  const idpTracingRecordPromise = getTracingRecord(resourceId).catch(_ => null);
  const [order, item, idpTracingRecord] = await Promise.all([orderPromise, itemPromise, idpTracingRecordPromise]);

  if (order) {
    const orderRequestItems = await getOrderRequestItems(order.orderId);
    const items = order.fulfillmentGroups
      .flatMap(fulfillmentGroup => fulfillmentGroup.items)
      .map(item => {
        return {
          ...item,
          mcpSku: item.skuCode,
          requestDate: item.requestDate,
          merchantId: order.merchantId,
          buyerAccountUrl: item._links.buyerAccount.href,
          productConfigurationUrl: item._links.productConfiguration.href,
          itemUrl: item._links.self.href,
        };
      });

    const itemsWithDeliveryRequestDetails = await bPromise.map(items, async item => {
      const orderedItem = find(orderRequestItems, ori => ori.itemId === item.itemId);
      const deliveryRequest = await fetchWithAuth<DeliveryRequest>({
        endpointUrl: item.deliveryConfigurations[0].deliveryRequestLink.href,
      });
      return {
        ...item,
        deliveryRequest,
        productConfigurationUrl: orderedItem?._links.productConfiguration.href || item.productConfigurationUrl,
        mcpSku: orderedItem?.skuCode || item.mcpSku,
      };
    });
    return { items: itemsWithDeliveryRequestDetails };
  } else if (item) {
    const orderRequestItems = await getOrderRequestItems(item.orderId);
    const orderedItem = find(orderRequestItems, ori => ori.itemId === item.itemId);
    return {
      items: [
        {
          ...item,
          productConfigurationUrl: orderedItem?._links.productConfiguration.href || item.productConfigurationUrl,
          mcpSku: orderedItem?.skuCode || item.mcpSku,
        },
      ],
    };
  } else if (idpTracingRecord) {
    return {
      idpTracingRecord,
    };
  }
  return;
};

import fetchWithAuth from './fetchWithAuth';

type Merchant = {
  merchantId: string;
  displayName: string;
};

export const getMerchants = async ({ hasAll = false, verbose = false, active = true } = {}): Promise<Merchant[]> => {
  const url = process.env.REACT_APP_MERCHANT_CONFIG_SERVICE_URL as string;
  return await fetchWithAuth({
    endpointUrl: url,
    route: '/v0/merchants',
    params: {
      hasAll: String(hasAll),
      verbose: String(verbose),
      active: String(active),
    },
  });
};

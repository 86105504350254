import produce from 'immer';

import { TabWasClickedAction } from './actionTypes';
import { CLICK_TAB } from './actionTypes';
import { DeliveryCalculatorState } from './defaults';

const reducer = produce((draft: DeliveryCalculatorState, action: TabWasClickedAction) => {
  switch (action.type) {
    case CLICK_TAB: {
      const { visitedTabId } = action.payload;
      draft.visitedTabs[visitedTabId] = true;
      return draft;
    }

    default: {
      return draft;
    }
  }
});

export default reducer;

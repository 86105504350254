import { Moment } from 'moment';
import { useMutation, useQuery } from 'react-query';

import { ItemState } from '../components/inputPanels/state';
import { HydratedCartEvaluation, getHydratedCartEvaluation } from '../helpers/edohydration';
import { createCartEvaluation } from '../services/edo';

type UseEDOCartEvaluationInput = {
  cartEvaluationUrl: string;
  enabled: boolean;
  onSuccess: ((data: HydratedCartEvaluation) => void) | undefined;
};

export type UseCreateCartEvaluationInput = {
  ecommerceDeliveryGroupId: string;
  items: ItemState[];
  country?: string | undefined;
  postalCode?: string | undefined;
  pickupPointUrl?: string | undefined;
  isPOBox?: boolean | undefined;
  requestDateTime?: Moment | undefined;
  onSuccess?: (data: HydratedCartEvaluation) => void;
};

export const useCreateCartEvaluation = ({
  ecommerceDeliveryGroupId,
  items,
  country,
  postalCode,
  pickupPointUrl,
  isPOBox,
  requestDateTime,
  onSuccess,
}: UseCreateCartEvaluationInput) => {
  return useMutation(
    ['debugBranches', ecommerceDeliveryGroupId, items, country, postalCode, pickupPointUrl, isPOBox, requestDateTime],
    () =>
      createCartEvaluation({
        ecommerceDeliveryGroupId,
        items,
        country,
        postalCode,
        pickupPointUrl,
        isPOBox,
        requestDateTime,
      }),
    {
      onSuccess,
    },
  );
};

export const useEDOCartEvaluation = ({ cartEvaluationUrl, enabled, onSuccess }: UseEDOCartEvaluationInput) => {
  return useQuery(['cartEvaluationUrl', cartEvaluationUrl], () => getHydratedCartEvaluation(cartEvaluationUrl), {
    enabled,
    retry: false,
    onSuccess,
  });
};

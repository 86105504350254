import {
  MERCHANT_TAB_ID,
  PRODUCT_TAB_ID,
  LOCATION_TAB_ID,
  DELIVERY_CONSTRAINTS_TAB_ID,
  NavigationTabId,
  CartNavigationTabId,
  DELIVERY_GROUP_TAB_ID,
  ITEMS_TAB_ID,
} from '../components/inputPanels/state';
import { ProductCardIndexAndDetails } from './actionTypes';

export type DeliveryCalculatorState = {
  visitedTabs: {
    [P in NavigationTabId]: boolean;
  };
};

export type OptionCalculatorState = {
  visitedTabs: {
    [P in CartNavigationTabId]: boolean;
  };
};

export const defaultDeliveryCalculatorState: DeliveryCalculatorState = {
  visitedTabs: {
    [MERCHANT_TAB_ID]: false,
    [PRODUCT_TAB_ID]: false,
    [LOCATION_TAB_ID]: false,
    [DELIVERY_CONSTRAINTS_TAB_ID]: false,
  },
};

export const defaultOptionCalculatorState: OptionCalculatorState = {
  visitedTabs: {
    [DELIVERY_GROUP_TAB_ID]: false,
    [ITEMS_TAB_ID]: false,
    [LOCATION_TAB_ID]: false,
  },
};

export const defaultDetails: ProductCardIndexAndDetails = {
  caveats: [],
  index: undefined,
};

import { Link } from '../types';
import fetchWithAuth from './fetchWithAuth';

export type OrderRequestItem = {
  itemId: string;
  skuCode: string;
  _links: {
    productConfiguration: Link;
  };
};

const url = process.env.REACT_APP_ORDER_REQUEST_URL as string;

export const getOrderRequestItems = async (orderId: string): Promise<OrderRequestItem[]> => {
  return await fetchWithAuth({
    endpointUrl: url,
    route: `/v1/orders/${orderId}/items`,
  });
};

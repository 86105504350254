import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveryCalculator: {
    id: 'homepage.deliveryCalculator',
    defaultMessage: 'Delivery Calculator - Dates',
  },
  optionCalculator: {
    id: 'optionspage.optionCalculator',
    defaultMessage: 'Delivery Calculator - Options',
  },
  merchant: {
    id: 'homepage.merchant',
    defaultMessage: 'Merchant',
  },
  deliveryGroup: {
    id: 'optionspage.deliveryGroup',
    defaultMessage: 'Delivery Group',
  },
  products: {
    id: 'optionspage.products',
    defaultMessage: 'Products',
  },
  product: {
    id: 'homepage.product',
    defaultMessage: 'Product',
  },
  location: {
    id: 'homepage.location',
    defaultMessage: 'Location',
  },
  deliveryConstraints: {
    id: 'homepage.deliveryConstraints',
    defaultMessage: 'Delivery Constraints',
  },
  currency: {
    id: 'settings.currency',
    defaultMessage: 'Currency',
  },
  currencyHelpText: {
    id: 'settings.currencyHelpText',
    defaultMessage: 'Select the desired currency to view the prices in',
  },
  items: {
    id: 'homepage.items',
    defaultMessage: 'Items',
  },
});

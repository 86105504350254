import fetchWithAuth from './fetchWithAuth';

type Account = {
  representativeMerchantId: string;
};

export const getMerchantId = async (accountId?: string): Promise<string> => {
  const response = await fetchWithAuth<Account>({
    method: 'GET',
    endpointUrl: process.env.REACT_APP_ROUTING_INGEST_URL as string,
    route: `/v1/accounts/${accountId}`,
  });

  return response.representativeMerchantId;
};

import { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { Moment } from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

import {
  DeliveryConstraintsState,
  LocationState,
  MerchantState,
  ProductState,
  DeliveryGroupState,
  ItemsState,
  defaultMerchantState,
  defaultDeliveryConstraintsState,
  defaultLocationState,
  defaultProductState,
  defaultDeliveryGroupState,
  defaultItemsState,
} from '../components/inputPanels/state';
import { HydratedCartEvaluation } from '../helpers/edohydration';
import { decodeValue, encodeValue } from '../helpers/urlhelpers';

const fieldsToExcludeFromUrl = ['clearSearchOnUpdate', 'cartEvaluation', 'items'];

export type CalculatorState = {
  idpTracingRecordId?: string;
  cartEvaluationUrl?: string;
  cartEvaluation?: HydratedCartEvaluation;
  itemId?: string;
  search?: string;
  skuOrProductConfiguration?: string;
  clearSearchOnUpdate: boolean;
} & DeliveryConstraintsState &
  LocationState &
  MerchantState &
  ProductState &
  DeliveryGroupState &
  ItemsState;

const defaultState: CalculatorState = {
  idpTracingRecordId: '',
  cartEvaluationUrl: '',
  itemId: '',
  search: '',
  ...defaultMerchantState,
  ...defaultDeliveryConstraintsState,
  ...defaultLocationState,
  ...defaultProductState,
  ...defaultDeliveryGroupState,
  ...defaultItemsState,
  clearSearchOnUpdate: false,
};

export function useCalculatorState() {
  const history = useHistory();
  const currentSearchParams = new URLSearchParams(history.location.search);

  // Find all the object keys in the search params
  // and attempt to reassemble the object from them
  const expectedKeys = Object.keys(defaultState); //.filter(key => !fieldsToExcludeFromUrl.includes(key));
  const initialValue = _.reduce(
    expectedKeys,
    (accumulator, key) => {
      const valueFromSearchParams = currentSearchParams.get(key);
      const isDateValue = /(date)|(time)/i.test(key);

      const decodedValue = decodeValue(valueFromSearchParams, isDateValue);
      // use default values if there wasn't a key in search params
      (accumulator as Record<string, unknown>)[key] = decodedValue || (defaultState as Record<string, unknown>)[key];
      return accumulator;
    },
    {} as CalculatorState,
  );

  // The skuOrProductConfiguration is used to set the initial input value for the product SKU/config
  // so we should set it here if we have a value for it from the query params
  if (initialValue.mcpSku || initialValue.productConfigurationUrl) {
    initialValue.skuOrProductConfiguration = initialValue.mcpSku || initialValue.productConfigurationUrl;
  }

  const [state, setState] = useImmer<CalculatorState>(initialValue);

  // Update the URL whenever state changes
  useEffect(() => {
    const currentSearchParams = new URLSearchParams();
    const fields = Object.entries(state);
    // Store all the object keys in the URL
    _.forEach(fields, ([key, value]) => {
      if (valueIsTruthy(value) && !fieldsToExcludeFromUrl.includes(key)) {
        const urlEncodedValue = encodeValue(value as string | Moment);
        currentSearchParams.set(key, urlEncodedValue);
      }
    });
    history.replace({ search: currentSearchParams.toString() });
  }, [state, history]);

  const updateState = (update: Partial<CalculatorState>) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);
      Object.assign(draft, update);
    });
  };

  const updateLocationState = (update: LocationState) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);
      draft.postalCode = update.postalCode;
      draft.deliveryCountry = update.deliveryCountry;
      draft.poBox = update.poBox;
      draft.addressType = update.addressType;
      draft.pickupPointUrl = update.pickupPointUrl;
    });
  };

  const updateProductState = (update: ProductState) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);
      draft.mcpSku = update.mcpSku;
      draft.productConfigurationUrl = update.productConfigurationUrl;
    });
  };

  const updateMerchantState = (update: MerchantState) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);
      draft.minutesToOrderSubmittal = update.minutesToOrderSubmittal;
      draft.merchantId = update.merchantId;
      draft.requestDate = update.requestDate;
      draft.ignoreInTransitInventory = update.ignoreInTransitInventory;
    });
  };

  const updateDeliveryGroupState = (update: DeliveryGroupState) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);
      draft.deliveryGroupId = update.deliveryGroupId;
      draft.requestDate = update.requestDate;
    });
  };

  const updateItemsState = (update: ItemsState) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);

      draft.items = update.items;
    });
  };

  const updateDeliveryConstraintsState = (update: DeliveryConstraintsState) => {
    setState(draft => {
      clearSearchValues(draft.clearSearchOnUpdate, draft);
      draft.fulfillmentCapabilities = update.fulfillmentCapabilities;
      draft.carrierServiceCapabilities = update.carrierServiceCapabilities;
      draft.carrierServices = update.carrierServices;
    });
  };

  return {
    state,
    updateState,
    updateLocationState,
    updateProductState,
    updateMerchantState,
    updateDeliveryConstraintsState,
    updateDeliveryGroupState,
    updateItemsState,
  };
}

const valueIsTruthy = (obj: unknown) => {
  if (Array.isArray(obj)) {
    return obj.length > 0;
  }
  return Boolean(obj);
};

function clearSearchValues(clearSearch: boolean, draft: WritableDraft<CalculatorState>) {
  if (clearSearch) {
    draft.search = '';
    draft.itemId = '';
    draft.idpTracingRecordId = '';
    draft.cartEvaluationUrl = '';
  }
}

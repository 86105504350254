import moment, { Moment } from 'moment-timezone';
import { useIntl } from 'react-intl';

import { Checkbox, TextField } from '@cimpress/react-components';
import DatePicker from '@cimpress/react-components/lib/DatePicker';

import { SelectField } from '../types';
import MerchantSelector from './MerchantSelector';
import messages from './messages';
import { MerchantState } from './state';

type MerchantInputPanelProps = {
  merchantState: MerchantState;
  setMerchantState: (v: MerchantState) => void;
  disabled: boolean;
  timezone: string | undefined;
};

const MerchantInputPanel = ({ merchantState, setMerchantState, disabled, timezone }: MerchantInputPanelProps) => {
  const { formatMessage } = useIntl();

  const onChangeMerchant = (selection: SelectField) => {
    setMerchantState({ ...merchantState, merchantId: selection.value });
  };

  const onChangeMinutesToOrderSubmittal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMerchantState({ ...merchantState, minutesToOrderSubmittal: parseInt(e.target.value) });
  };

  const onChangeRequestDate = (date: string | Moment) => {
    const asMoment = typeof date === 'string' ? moment(date) : date;
    setMerchantState({ ...merchantState, requestDate: asMoment });
  };

  const onChangeIgnoreInTransitInventory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState: MerchantState = {
      ...merchantState,
      ignoreInTransitInventory: !merchantState.ignoreInTransitInventory,
    };
    setMerchantState(newState);
  };

  return (
    <div>
      <h3>{formatMessage(messages.selectAMerchant)}</h3>
      <MerchantSelector value={merchantState.merchantId} onChange={onChangeMerchant} required isDisabled={disabled} />

      <h3>{formatMessage(messages.minutesToOrderSubmittal)}</h3>
      <TextField
        type="number"
        min="0"
        value={merchantState.minutesToOrderSubmittal}
        onChange={onChangeMinutesToOrderSubmittal}
        disabled={disabled}
      />

      <h3>{formatMessage(messages.requestDate)}</h3>
      <DatePicker
        label={formatMessage(messages.selectADate)}
        value={timezone ? merchantState.requestDate?.tz(timezone) : merchantState.requestDate}
        onChange={onChangeRequestDate}
        isClearable={true}
        disabled={disabled}
      />

      <Checkbox
        label={formatMessage(messages.ignoreInTransitInventory)}
        checked={merchantState.ignoreInTransitInventory}
        onChange={onChangeIgnoreInTransitInventory}
        disabled={disabled}
      />
    </div>
  );
};

export default MerchantInputPanel;

import { css } from 'emotion';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useReducer, useState } from 'react';

import {
  IconCheckCircleAlt,
  IconAlertTriangle,
  IconRemoveCircle,
  IconButtonPause,
} from '@cimpress-technology/react-streamline-icons';
import { Accordion, Button, Card, FlexBox, Tooltip, colors } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';

import { HydratedCartEvaluation } from '../helpers/edohydration';
import { getDefaultPostalCodeOrEmpty } from '../helpers/postalcodes';
import { useCalculatorState } from '../hooks/useCalculatorState';
import { useCreateCartEvaluation, useEDOCartEvaluation } from '../hooks/useEDOCartEvaluation';
import { useItemWithCartEvaluation } from '../hooks/useItem';
import { useCartResourceSearch } from '../hooks/useResourceSearch';
import { useGlobalSettings } from '../hooks/useSettings';
import cartTabActionReducer from '../reducers/cartTabActionReducer';
import { defaultOptionCalculatorState } from '../reducers/defaults';
import {
  CartEvaluationItem,
  DeliveryOption,
  DeliveryOptionItem,
  EcommerceDeliveryOption,
  InvalidItem,
} from '../services/edo';
import { Item, ItemWithCartEvaluation } from '../services/items';
import CartInputTabs from './CartInputTabs';
import { CaveatTable } from './Caveats';
import DottedLine from './DottedLine';
import ItemSelector from './ItemSelector';
import ResultPanelPreCartSearch from './ResultPanelPreCartSearch';
import SearchInput from './SearchInput';
import {
  DeliveryGroupState,
  ItemsState,
  LocationState,
  defaultDeliveryGroupState,
  defaultLocationState,
} from './inputPanels/state';
import { SelectField } from './types';

const gridContainerCss = css`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
`;

const resultsHeaderCss = css`
  background-color: #ffebdb;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0 !important;
  padding: 5px;
  p:last-child {
    margin: 0;
  }
`;

const resultPreSearchCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const buttonCss = css`
  height: 48px;
  width: 20%;
  float: right;
`;

const CartEvaluationsPage = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [loadedCartEvaluationUrl, setLoadedCartEvaluationUrl] = useState('');
  const [loadedItemId, setLoadedItemId] = useState('');

  const { state, updateState, updateLocationState, updateDeliveryGroupState, updateItemsState } = useCalculatorState();
  const { search, itemId, cartEvaluation, cartEvaluationUrl } = state;
  const { data: globalSettingsData } = useGlobalSettings({});

  const [optionCalculatorState, optionCalculatorDispatch] = useReducer(
    cartTabActionReducer,
    defaultOptionCalculatorState,
  );

  const { isLoading: isLoadingSearch, refetch: refetchSearch } = useCartResourceSearch({
    resourceId: search || '',
    timezone: globalSettingsData?.global?.timezone,
    onSuccess: data => {
      if (data?.cartEvaluation) {
        populateFromCartEvaluation(data.cartEvaluation);
      } else if (data?.items) {
        setItems(data?.items ?? []);
        const allItemIds = data?.items?.map(item => item.itemId) ?? [];
        const selectedItemId = allItemIds.includes(itemId || '') ? itemId : allItemIds[0] ?? '';
        updateState({ itemId: selectedItemId });
        if (data.items.length === 1) {
          populateFromItem(data.items[0]);
        }
      }
    },
  });

  const postalCode = state.postalCode || getDefaultPostalCodeOrEmpty(state.deliveryCountry);
  const isCompleteSelection =
    state.items.every(i => !isEmpty(i.productConfigurationUrl)) &&
    !isEmpty(state.deliveryGroupId) &&
    !isEmpty(state.deliveryCountry) &&
    !isEmpty(postalCode);

  const { isLoading: calculationIsLoading, mutate: calculate } = useCreateCartEvaluation({
    ecommerceDeliveryGroupId: state.deliveryGroupId,
    items: state.items,
    country: state.deliveryCountry,
    postalCode,
    isPOBox: state.poBox,
    requestDateTime: state.requestDate,
    onSuccess: data => {
      populateFromCartEvaluation(data);
    },
  });

  const { isLoading: isLoadingIDPTracingRecord } = useEDOCartEvaluation({
    cartEvaluationUrl: cartEvaluationUrl || '',
    enabled: cartEvaluationUrl !== loadedCartEvaluationUrl,
    onSuccess: cartEval => cartEval && populateFromCartEvaluation(cartEval),
  });

  const { isLoading: isLoadingItem } = useItemWithCartEvaluation({
    itemId: itemId || '',
    timezone: globalSettingsData?.global?.timezone,
    enabled: itemId !== loadedItemId,
    onSuccess: item => {
      populateFromItem(item);
    },
  });

  const populateFromCartEvaluation = (cartEval: HydratedCartEvaluation) => {
    const deliveryGroupState: DeliveryGroupState = {
      deliveryGroupId: cartEval.deliveryGroupId || defaultDeliveryGroupState.deliveryGroupId,
      requestDate: cartEval.requestDateTime ? moment(cartEval.requestDateTime) : moment(cartEval.createdAt),
    };

    let locationState: LocationState;
    const pickupPointUrl = cartEval.pickupPointUrl;

    if (pickupPointUrl) {
      locationState = { ...defaultLocationState, pickupPointUrl };
    } else {
      locationState = {
        deliveryCountry: cartEval.country || defaultLocationState.deliveryCountry,
        postalCode: cartEval.postalCode || defaultLocationState.postalCode,
        poBox: cartEval.isPOBox || false,
        pickupPointUrl: defaultLocationState.pickupPointUrl,
      };
    }

    const itemsState: ItemsState = { items: [] };
    cartEval.items.forEach(item =>
      itemsState.items.push({
        key: item.key,
        productConfigurationUrl: item.productConfigurationUrl || '',
        mcpSku: cartEval.keyedItems[item.key].sku || '',
        skuOrProductConfiguration: cartEval.keyedItems[item.key].sku || '',
      }),
    );
    setLoadedCartEvaluationUrl(cartEval._links.self.href);
    updateState({
      ...deliveryGroupState,
      ...locationState,
      ...itemsState,
      itemId: '',
      search: '',
      cartEvaluation: cartEval,
      cartEvaluationUrl: cartEval._links.self.href,
      clearSearchOnUpdate: true,
    });
  };

  const populateFromItem = (item: ItemWithCartEvaluation | undefined) => {
    if (item) {
      const deliveryGroupState: DeliveryGroupState = {
        deliveryGroupId: item.cartEvaluation?.deliveryGroupId || defaultDeliveryGroupState.deliveryGroupId,
        requestDate: item.cartEvaluation?.requestDateTime
          ? moment(item.cartEvaluation?.requestDateTime)
          : moment(item.cartEvaluation?.createdAt),
      };

      let locationState: LocationState;
      const pickupPointUrl = item.cartEvaluation?.pickupPointUrl;

      if (pickupPointUrl) {
        locationState = { ...defaultLocationState, pickupPointUrl };
      } else {
        locationState = {
          deliveryCountry: item.cartEvaluation?.country || defaultLocationState.deliveryCountry,
          postalCode: item.cartEvaluation?.postalCode || defaultLocationState.postalCode,
          poBox: item.cartEvaluation?.isPOBox || false,
          pickupPointUrl: defaultLocationState.pickupPointUrl,
        };
      }

      const itemsState: ItemsState = { items: [] };
      item.cartEvaluation?.items.forEach(i =>
        itemsState.items.push({
          key: i.key,
          productConfigurationUrl: i.productConfigurationUrl || '',
          mcpSku: item.cartEvaluation?.keyedItems[i.key].sku || '',
          skuOrProductConfiguration: item.cartEvaluation?.keyedItems[i.key].sku || '',
        }),
      );
      setLoadedItemId(item.itemId);
      updateState({
        ...deliveryGroupState,
        ...locationState,
        ...itemsState,
        itemId: item.itemId,
        search: '',
        cartEvaluation: item.cartEvaluation,
        clearSearchOnUpdate: true,
      });
    }
  };

  const cartEvaluationResults = cartEvaluation ? (
    <Card>
      <div className={resultsHeaderCss}>
        <p>Evaluation requested on {moment(cartEvaluation.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
        <p>Estimates below expire on {moment(cartEvaluation.expiryDate).format('MMMM Do YYYY, h:mm:ss a')}</p>
      </div>
      {cartEvaluation?.deliveryOptions.map(i => (
        <DeliveryOptionPanel
          key={i.id}
          deliveryOption={i}
          keyedItems={cartEvaluation.keyedItems}
          edo={cartEvaluation.keyedEdos[i._links.self.href]}
        />
      ))}
    </Card>
  ) : (
    <Card className={resultPreSearchCss}>
      <ResultPanelPreCartSearch />
    </Card>
  );

  const onChangeSelectedItem = (selection: SelectField) => {
    const item = items?.find(item => item.itemId === selection.value);
    updateState({ itemId: item?.itemId });
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ search: e.target.value });
  };

  const onSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateState({ search: search?.trim() });
    refetchSearch();
  };

  const onCalculateCart = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    calculate();
  };

  return (
    <div className="container-fluid">
      <div className={gridContainerCss}>
        <Card>
          <h4>Populate from an existing order, item, or EDO tracing record:</h4>
          <SearchInput
            value={search || ''}
            onChange={onSearchChange}
            onSubmit={onSubmitSearch}
            isLoading={isLoadingSearch}
          />
          {items.length > 1 ? <ItemSelector items={items} value={itemId} onChange={onChangeSelectedItem} /> : <></>}
          <DottedLine />
          <h4>Or input manually:</h4>
          <CartInputTabs
            state={state}
            setDeliveryGroupState={updateDeliveryGroupState}
            setItemsState={updateItemsState}
            setLocationState={updateLocationState}
            isLoadingItem={isLoadingItem || isLoadingIDPTracingRecord}
            dispatch={optionCalculatorDispatch}
            optionCalculatorState={optionCalculatorState}
            timezone={globalSettingsData?.global?.timezone}
          />
          <Button variant="primary" className={buttonCss} onClick={onCalculateCart} disabled={!isCompleteSelection}>
            {calculationIsLoading ? <Spinner size="small" /> : 'Calculate'}
          </Button>
        </Card>
        {cartEvaluationResults}
      </div>
    </div>
  );
};

export default CartEvaluationsPage;

type DeliveryOptionPanelProps = {
  deliveryOption: DeliveryOption;
  keyedItems: { [key: string]: CartEvaluationItem };
  edo: EcommerceDeliveryOption;
};

const DeliveryOptionPanel: React.FC<DeliveryOptionPanelProps> = ({ deliveryOption, keyedItems, edo }) => {
  const optionStatus =
    deliveryOption.invalidItems.length === 0 ? (
      <IconCheckCircleAlt size="lg" color={colors.success.base} weight="fill" style={{ marginRight: '5px' }} />
    ) : deliveryOption.invalidItems.length === Object.keys(keyedItems).length ? (
      <IconRemoveCircle size="lg" color={colors.danger.base} weight="fill" style={{ marginRight: '5px' }} />
    ) : (
      <IconAlertTriangle size="lg" color={colors.warning.base} weight="fill" style={{ marginRight: '5px' }} />
    );

  const header = deliveryOption.date ? (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      {optionStatus} {deliveryOption.name} - Arrives {moment(deliveryOption.date).format('MMMM DD YYYY')}
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      {optionStatus} {deliveryOption.name} - Option Not Available
    </div>
  );
  return (
    <Card header={header} style={{ marginBottom: 20 }}>
      <>
        <div>{generateDeliveryDayDescription(edo)}</div>
        <FlexBox marginX="xl" isVertical={true}>
          {!!deliveryOption.items.length && (
            <div style={{ width: '100%' }}>
              {deliveryOption.items.map(i => (
                <ItemDetail
                  key={`${deliveryOption.id}-${i.key}`}
                  deliveryOptionItem={i}
                  hydratedItem={keyedItems[i.key]}
                />
              ))}
            </div>
          )}
          {!!deliveryOption.invalidItems.length && (
            <div style={{ width: '100%' }}>
              {deliveryOption.invalidItems.map(i => (
                <div key={i.key}>
                  <InvalidItemDetail
                    key={`${deliveryOption.id}-${i.key}`}
                    invalidItem={i}
                    hydratedItem={keyedItems[i.key]}
                  />
                </div>
              ))}
            </div>
          )}
        </FlexBox>
      </>
    </Card>
  );
};

const ItemDetail: React.FC<{
  deliveryOptionItem: DeliveryOptionItem;
  hydratedItem: CartEvaluationItem;
}> = ({ deliveryOptionItem, hydratedItem }) => {
  const itemStatus = deliveryOptionItem.fallback ? (
    <Tooltip contents="Item experienced a fallback">
      <IconButtonPause size="lg" color={colors.warning.base} weight="fill" />
    </Tooltip>
  ) : (
    <Tooltip contents="Item is available for this delivery option">
      <IconCheckCircleAlt size="lg" color={colors.success.base} weight="fill" />
    </Tooltip>
  );

  const caveats = deliveryOptionItem.idpTracingRecord?._embedded.caveats ?? [];

  return (
    <div>
      <Accordion
        title={`${hydratedItem.name} | ${hydratedItem.key}`}
        key={deliveryOptionItem.key}
        bodyStyle={{ padding: '20px', paddingLeft: '50px' }}
        variant="ghost"
        actions={<div style={{ display: 'flex', alignItems: 'center' }}>{itemStatus}</div>}
      >
        {!!deliveryOptionItem.fallback && (
          <div>
            <b>
              <p>This delivery option used fallback dates</p>
            </b>
          </div>
        )}

        <p>SKU - {hydratedItem.sku}</p>
        <p>Quantity - {hydratedItem.deliverableQuantity} </p>
        <p>Deliverable Date - {deliveryOptionItem.dates[0]}</p>

        {!isEmpty(caveats) && (
          <div>
            <b> The following IDP Caveats occurred: </b>
            <CaveatTable caveats={caveats} />
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
          <Button variant="anchor" href={deliveryOptionItem.idpTracingRecord?._links.ui.href} target="_blank">
            View IDP Diagnostics
          </Button>
        </div>
      </Accordion>
    </div>
  );
};

const InvalidItemDetail: React.FC<{
  invalidItem: InvalidItem;
  hydratedItem: CartEvaluationItem;
}> = ({ invalidItem, hydratedItem }) => {
  const itemStatus = (
    <Tooltip contents="Item is not available for this delivery option">
      <IconAlertTriangle size="lg" color={colors.danger.base} weight="fill" />
    </Tooltip>
  );
  const caveats = invalidItem._embedded?.caveats?.warnings ?? [];
  return (
    <div>
      <Accordion
        title={`${hydratedItem.name} | ${hydratedItem.key}`}
        key={invalidItem.key}
        variant="ghost"
        actions={<div style={{ display: 'flex', alignItems: 'center' }}>{itemStatus}</div>}
      >
        <p>SKU - {hydratedItem.sku}</p>
        <p>Quantity - {hydratedItem.deliverableQuantity} </p>
        <CaveatTable caveats={caveats} />
        {invalidItem.idpTracingRecord ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
            <Button variant="anchor" href={invalidItem.idpTracingRecord?._links.ui.href} target="_blank">
              View IDP Diagnostics
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
};

function generateDeliveryDayDescription({ minDays, maxDays, minOffset, maxOffset }: EcommerceDeliveryOption) {
  if (minDays && maxDays) {
    return minDays === maxDays ? `${minDays} day(s)` : `Between ${minDays} and ${maxDays} days`;
  }
  if (minDays) {
    return `Greater than ${minDays} day(s)`;
  }
  if (maxDays) {
    return `Less than ${maxDays} day(s)`;
  }
  if (minOffset === 0 && maxOffset === 0) {
    return `The earliest deliverable date`;
  }
  if (minOffset && maxOffset) {
    return minOffset === maxOffset
      ? `${minOffset} day(s) from earliest date`
      : `Between ${minOffset} and ${maxOffset} days from earliest date`;
  }
  if (minOffset) {
    return `At least ${minOffset} day(s) from earliest date`;
  }
  if (maxOffset) {
    return `At most ${maxOffset} day(s) from earliest date`;
  }
  return '';
}

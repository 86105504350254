import React from 'react';

import { AppSettings, SelectField } from '../types';
import CurrencySelector from './CurrencySelector';

type AppSettingsProps = {
  appSettings?: AppSettings;
  onChange: (selection: AppSettings) => void;
};

const defaultCurrencySelection = {
  label: 'USD',
  value: 'USD',
};

const AppSettingsConfig = ({ appSettings, onChange }: AppSettingsProps) => {
  const onChangeCurrencyCode = (selection: SelectField) => {
    onChange({ ...appSettings, currencyCode: selection });
  };

  return (
    <CurrencySelector
      onChange={onChangeCurrencyCode}
      selection={appSettings?.currencyCode ?? defaultCurrencySelection}
    />
  );
};

export default AppSettingsConfig;

import { useQuery } from 'react-query';

import { CartSearchResponse, existingCartResourceSearch } from '../services/cartResourceSearch';
import { existingResourceSearch, SearchResponse } from '../services/resourceSearch';

type UseResourceSearchInput = {
  resourceId: string;
  timezone: string | undefined;
  onSuccess: ((data: SearchResponse | undefined) => void) | undefined;
};

type UseCartResourceSearchInput = {
  resourceId: string;
  timezone: string | undefined;
  onSuccess: ((data: CartSearchResponse | undefined) => void) | undefined;
};

export const useResourceSearch = ({ resourceId, timezone, onSuccess }: UseResourceSearchInput) => {
  return useQuery(['existing-resource-search'], () => existingResourceSearch({ resourceId, timezone }), {
    enabled: false,
    retry: false,
    onSuccess,
  });
};

export const useCartResourceSearch = ({ resourceId, timezone, onSuccess }: UseCartResourceSearchInput) => {
  return useQuery(['existing-resource-search'], () => existingCartResourceSearch({ resourceId, timezone }), {
    enabled: false,
    retry: false,
    onSuccess,
  });
};
